<template>
  <Breadcrumb :items="breadcrumbs" />
  <h1>404 {{ t('page_not_found') }}</h1>
</template>
<script>
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'

export default {
  name: 'notFound',
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('404')
    })
    return {
      t,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('404'), href: null, active: true }
      ]
    }
  }
}
</script>
